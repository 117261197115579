// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#erxes-knowledge-container {
  min-height: 100%;
  background: #fcfcfc;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.erxes-knowledge-container-shoutbox {
  /* shoutbox css below */
  position: absolute;
  right: 0;
  max-width: 300px;
  min-width: 300px;
}


#erxes-knowledge-iframe {
  width: 100%;
  border: none;
  min-height: 100%;
  position: absolute;
}

@media only screen and (max-width: 420px) {
  #erxes-knowledge-container {
    width: 100%;
    max-height: none;
  }
}
`, "",{"version":3,"sources":["webpack://./client/knowledgebase/widget/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;EACR,gBAAgB;EAChB,gBAAgB;AAClB;;;AAGA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":["#erxes-knowledge-container {\n  min-height: 100%;\n  background: #fcfcfc;\n  border-radius: 10px;\n  position: relative;\n  overflow: hidden;\n}\n\n.erxes-knowledge-container-shoutbox {\n  /* shoutbox css below */\n  position: absolute;\n  right: 0;\n  max-width: 300px;\n  min-width: 300px;\n}\n\n\n#erxes-knowledge-iframe {\n  width: 100%;\n  border: none;\n  min-height: 100%;\n  position: absolute;\n}\n\n@media only screen and (max-width: 420px) {\n  #erxes-knowledge-container {\n    width: 100%;\n    max-height: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
